import email from '../assets/email.png';
import mobile from '../assets/mobile.png';
import profile from '../assets/profile.png';
import circle from '../assets/circle.svg';
import logo from '../assets/logo.png';
import sticky from '../assets/sticky-back.jpg';
import user from '../assets/user.png'
import back1 from '../assets/back1.png'
import back2 from '../assets/back2.png'




const images ={
  email,
  mobile,
  profile,
  circle,
  logo,
  sticky,
  user,
  back1,
  back2,
};


export default images;